// Variabels
$max-width: 1440px;
$desktop: 1024px;
$landscape: 812px;
$mobile: 600px;
$mobile-small: 320px;
$footer-max-width: 1110px;

// Mixins
@mixin button {
  padding: 0;
  outline: none;
  color: #fcfcfc;
  cursor: pointer;
  border-radius: 4px;
  letter-spacing: 1px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  box-shadow: 0 7px 25px 0 rgba(44,195,138,.2);
  -webkit-box-shadow: 0 7px 25px 0 rgba(44,195,138,.2);
}

@mixin disable-selection {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.bg-omu-landing {
  background-image: url('../assets/background/omu-landing.svg')
}

// Export variabels
:export {
  maxWidth: $max-width;
  desktop: $desktop;
  landscape: $landscape;
  mobile: $mobile;
  mobileSmall: $mobile-small;
  footerMaxWith: $footer-max-width;
}
