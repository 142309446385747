@import "./common";
@import "./var";

// Page container template
.page-container {
  padding-top: 150px;
}

@media only screen and (max-width: $landscape) {
  .page-container {
    padding-top: 90px;
  }
}

// Card template
.card-shadow {
  box-shadow: 0 7px 25px 0 rgba(0,0,0,.05);
  -webkit-box-shadow: 0 7px 25px 0 rgba(0,0,0,.05);
}

// Button template
.main-disabled-button {
  @include button;
  box-shadow: none;
  -webkit-box-shadow: none;
  background: linear-gradient(to bottom, rgba(8,34,24,.2), rgba(8,34,24,.2));
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(8,34,24,.2)), to(rgba(8,34,24,.2)));
}

.main-button {
  @include button;
  background-color: #2cc38a;
}
.main-button:hover {
  background-color: #2bab7b;
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
}

.main-gradient-button {
  @include button;
  background: linear-gradient(to bottom, rgb(67,207,162), rgb(53,190,151));
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(67,207,162)), to(rgb(53,190,151)));
}
.main-gradient-button:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
}

.disabled-gradient-button {
  @include button;
  background: #ddd;
  cursor: default !important;
}

.main-white-button {
  @include button;
  color: $taro-secondary;
  background-color: #ffffff;
}

.main-white-button:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
}

.d--flex {
  display: flex;
}
.j-content--start {
  justify-content: flex-start;
}
.j-content--center {
  justify-content: center;
}
.j-content--end {
  justify-content: flex-end;
}
.j-content--space-between {
  justify-content: space-between;
}
.j-content--space-around {
  justify-content: space-around;
}
.a-item--start {
  align-items: flex-start;
}
.a-item--center {
  align-items: center;
}
.a-item--end {
  align-items: flex-end;
}
.a-self--start {
  align-self: flex-start;
}
.a-self--center {
  align-self: center;
}
.a-self--end {
  align-self: flex-end;
}
.f-direction--column {
  flex-direction: column;
}
