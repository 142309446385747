@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap");

@font-face {
  font-family: "Eina01-Bold";
  font-display: swap;
  src: url("https://static.taralite.com/lending/general/assets/fonts/eina/eina01_bold-webfont.woff2") format("woff2"),
    url("https://static.taralite.com/lending/general/assets/fonts/eina/eina01_bold-webfont.woff") format("woff");
}

@font-face {
  font-family: "SF Pro Text";
  font-display: swap;
  src: url("https://static.taralite.com/lending/general/assets/fonts/sf-pro-text/sf_pro_text_regular-webfont.woff2")
      format("woff2"),
    url("https://static.taralite.com/lending/general/assets/fonts/sf-pro-text/sf_pro_text_regular-webfont.woff")
      format("woff");
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

// Remove spinner from input element
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  opacity: 0;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

// Remove reset button from input date
input[type="date"]::-webkit-clear-button {
  display: none;
}

input[type="date"]::-ms-clear {
  display: none;
}
