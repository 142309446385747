@import "../../themes/common";
@import "themes/_var.scss";

$progress-bar-color: $taro;

.progress-bar {
  top: 0;
  width: 100%;
  height: 4px;
  z-index: 10000;
  overflow: hidden;
  position: absolute;
  background-color: lighten($progress-bar-color, 40%);
  .indeterminate {
    background-color: $progress-bar-color;
  }
  .indeterminate::before {
    top: 0;
    left: 0;
    bottom: 0;
    content: '';
    position: absolute;
    will-change: left, right;
    background-color: inherit;
    animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  }
  .indeterminate::after {
    top: 0;
    left: 0;
    bottom: 0;
    content: '';
    position: absolute;
    animation-delay: 1.15s;
    will-change: left, right;
    background-color: inherit;
    -webkit-animation-delay: 1.15s;
    animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  }
  @-webkit-keyframes indeterminate {
    0% {left: -35%; right: 100%;}
    60% {left: 100%; right: -90%;}
    100% {left: 100%; right: -90%;}
  }
  @keyframes indeterminate {
    0% {left: -35%; right: 100%;}
    60% {left: 100%; right: -90%;}
    100% {left: 100%; right: -90%;}
  }
  @-webkit-keyframes indeterminate-short {
    0% {left: -200%; right: 100%;}
    60% {left: 107%; right: -8%;}
    100% {eft: 107%; right: -8%;}
  }
  @keyframes indeterminate-short {
    0% {left: -200%; right: 100%;}
    60% {left: 107%; right: -8%;}
    100% {left: 107%; right: -8%;}
  }
}
